import React, {useEffect} from "react";
import {connect} from 'react-redux';
import PageTitle from "../common/PageTitle";
import Title from "./HomeTitle";
import Menu from "../menu/Menu";
import Sketch from "./sketch/Sketch";
import Grain from "../common/Grain";
import {setBg} from "../../actions/UIActions";
import {isTablet, isMobile} from "react-device-detect";
import SketchMobile from "./sketch/SketchMobile";

function AvikunthakHome(props) {

    useEffect(()=> {
        props.dispatch(setBg("#ffffff"));
    },[]);

    return (
        <>
            <PageTitle title="Ashish Avikunthak"/>
            <div
                style={{
                    width: "100vw",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    height: "100vh",
                    overflow: "hidden"
                }}
            >
                <Menu style={{
                    zIndex: 99,
                    opacity: props.sketchExplored?1:0,
                    position: "absolute",
                }}></Menu>
                <div style={{
                    flex: 1,
                    width:  "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                }}
                >
                    {(isMobile || isTablet) && <SketchMobile/>}
                    {!(isMobile || isTablet) && <Sketch/>}
                    <Title/>
                </div>
            </div>
            <Grain opacity={0.08}/>
        </>
    );
}

function mapStateToProps(state) {
    return {
        size: state.cursorReducer.cursorSize,
        sketchExplored: state.uiReducer.sketchExplored
    };
}

export default connect(mapStateToProps)(AvikunthakHome);