import React, {useState} from "react";
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";
import {burgerClose} from "../../actions/BurgerActions";
import {changeCursorSize, showCursorImage, showCursorText} from "../../actions/CursorActions";
import {DEFAULT_CURSOR_SIZE, MENU_CURSOR_SIZE} from "../../constants/cursorConstants";

function PageLinkCard(props) {
    const [underline, setUnderline] = useState(false);
    return (
        <div className={'page-link'}
            onMouseEnter={() => {
                setUnderline(true);
                console.log(props.img)
                props.dispatch(showCursorImage(props.img))
                // props.dispatch(changeCursorSize(MENU_CURSOR_SIZE))
            }}
            onMouseLeave={() => {
                setUnderline(false);
                props.dispatch(changeCursorSize(DEFAULT_CURSOR_SIZE))
                // props.dispatch(changeCursorSize(DEFAULT_CURSOR_SIZE))
            }}
            onClick={() => {
                props.dispatch(changeCursorSize(DEFAULT_CURSOR_SIZE))
                props.dispatch(burgerClose())
            }}
        >
            <NavLink to={props.href} style={({ isActive }) => {
                return {
                    fontWeight: isActive ? "bold" : "",
                    color: isActive ? "#c52137" : "#ffffff",
                    textDecoration: "none",
                }
            }}>
                {props.title}
            </NavLink>
            <div style={{
                background: "#ffffff",
                width: "100%",
                height: "2px",
                position: "relative",
                left: underline?"0":"-100%",
                transition: "all 0.3s ease-in-out",
            }}>
            </div>
        </div>
    );
}

export default connect()(PageLinkCard);