import React from "react";
import {connect} from 'react-redux';

import {changeCursorSize} from "../../actions/CursorActions";
import {DEFAULT_CURSOR_SIZE, BIG_CURSOR_SIZE} from "../../constants/cursorConstants";

function WritingItem(props) {

    const item = props.data;
    return (
        <div className={'writings-details'}>
            <b>
                {
                    item.link &&
                    <a
                        href={item.link}
                        target={"_blank"}
                        onMouseEnter={() => {
                            props.dispatch(changeCursorSize(BIG_CURSOR_SIZE))
                        }}
                        onMouseLeave={() => {
                            props.dispatch(changeCursorSize(DEFAULT_CURSOR_SIZE))
                        }}
                    >
                        {item.date}
                    </a>
                }
                {
                    !item.link &&
                    item.date
                }
            </b>
            <div>
                <div>
                    {
                        item.link &&
                        <a
                            href={item.link}
                            target={"_blank"}
                            onMouseEnter={() => {
                                props.dispatch(changeCursorSize(BIG_CURSOR_SIZE))
                            }}
                            onMouseLeave={() => {
                                props.dispatch(changeCursorSize(DEFAULT_CURSOR_SIZE))
                            }}
                        >
                            {item.title}
                        </a>
                    }
                    {
                        !item.link &&
                        item.title
                    }
                </div>
                <div>
                    {
                        item.link &&
                        <a
                            href={item.link}
                            target={"_blank"}
                            onMouseEnter={() => {
                                props.dispatch(changeCursorSize(BIG_CURSOR_SIZE))
                            }}
                            onMouseLeave={() => {
                                props.dispatch(changeCursorSize(DEFAULT_CURSOR_SIZE))
                            }}
                        >
                            {item.publisher}
                        </a>
                    }
                    {
                        !item.link &&
                        item.publisher
                    }
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        size: state.cursorReducer.cursorSize
    };
}

export default connect(mapStateToProps)(WritingItem);