import React from "react";
import {connect} from 'react-redux';
import {burgerToggle} from "../../actions/BurgerActions";

function Menu(props) {
    return (
        <div className={'burger' + (props.burgerOpen ? ' clicked' : '') + (props.bg !== "#ffffff"? ' white' : '')}
             onClick={() => {
                 props.dispatch(burgerToggle())
             }}
             style={{
                 borderColor: props.bg
             }}
        >
            <div></div>
            <span style={{
                opacity: 1,
                background: props.bg
            }}></span>
            <div></div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        burgerOpen: state.burgerReducer.burgerOpen,
        bg: state.uiReducer.color
    };
}

export default connect(mapStateToProps)(Menu);