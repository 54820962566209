import {CURSOR_HIDE, CURSOR_MODE, IMAGE_MODE, CURSOR_SIZE, TEXT_MODE} from "../constants/cursorConstants";

export function cursorReducer(state= {}, action) {
    switch (action.type) {
        case CURSOR_SIZE:
            return {
                ...state,
                cursorSize: action.cursorSize,
                mode: CURSOR_MODE
            }
        case CURSOR_HIDE:
            return {
                ...state,
                cursorHide: action.cursorHide,
                mode: CURSOR_MODE
            }
        case TEXT_MODE:
            return {
                ...state,
                cursorText: action.text,
                cursorSize: 0,
                mode: TEXT_MODE
            }
        case IMAGE_MODE:
            return {
                ...state,
                cursorImg: action.img,
                cursorSize: 0,
                mode: IMAGE_MODE
            }
        default: return {
            ...state
        };
    }
}

