import React, {useEffect, useState} from "react";
import {connect} from 'react-redux';
import PageTitle from "../common/PageTitle";
import Menu from "../menu/Menu";
import PageHeading from "../common/PageHeading";
import {setBg} from "../../actions/UIActions";
import Grain from "../common/Grain";

import publishedBooks from "./published-books";
import {changeCursorSize} from "../../actions/CursorActions";
import {DEFAULT_CURSOR_SIZE, BIG_CURSOR_SIZE} from "../../constants/cursorConstants";
import articles from "./articles";
import Footer from "../common/Footer";
import WritingItem from "./WritingItem";

function Writings(props) {

    const [left, setLeft] = useState(0);

    const scrollHandler = (e) => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        setLeft(2*winScroll);
    };

    useEffect(()=> {
        props.dispatch(setBg("#c52137"));
        window.addEventListener("scroll", scrollHandler);
        return(() => {
            window.removeEventListener("scroll",scrollHandler);
        })
    },[])

    return (
        <>
            <PageTitle title="Writings | Ashish Avikunthak"/>
            <div>
                <Menu></Menu>
                <div style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                }}
                >
                    <PageHeading title={"Writings"} style={{
                        color: "#ffffff"
                    }}/>
                    <div className={'writings-container'}>
                        <div className={'writings-film-strip'}
                             style={{
                                 backgroundPosition: "0 " + -left + "px",
                             }}
                        >
                        </div>
                        <div className={'writings'}>
                            <div>
                                <h2>
                                    Published books
                                </h2>
                                {
                                    publishedBooks &&
                                    publishedBooks.map((item, index) => {
                                        return <WritingItem data={item} key={index}/>
                                    })
                                }
                            </div>
                            <div>
                                <h2>
                                    Selected Articles
                                </h2>
                                {
                                    articles &&
                                    articles.map((item, index) => {
                                        return <WritingItem data={item} key={index}/>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <Footer/>
                <Grain opacity={0.1}/>
            </>
            );
            }

            function mapStateToProps(state) {
            return {
            size: state.cursorReducer.cursorSize
        };
        }

            export default connect(mapStateToProps)(Writings);